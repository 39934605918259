import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Img from "gatsby-image";
import React from "react";

const headingText = { 
  main: "Amazing eh?",
  sub: "We want to help you get some of that leverage. To get started check your ✉️ inbox."
} 


function Welcome({data}) {
  return (
    <Layout>
      <SEO />
      <Section>
      
        <div style={{ marginTop: "100px" }}>
          <Headings.h1>{headingText.main}</Headings.h1>
        </div>
        <div style={{ marginTop: "75px" }}>
          <Img fluid={data.imageOne.childImageSharp.fluid} />
        </div>
        
        <div style={{ marginTop: "75px" }}>
        <Headings.h2>
        A gazillion bits and bytes just danced in tune to safely deliver your first That Leverage email direct to your inbox of choice.
        </Headings.h2>
        </div>
        <div style={{ marginTop: "50px" }}>
        <Headings.h4>
        Archimedes declared 'give me a lever, and a place to stand and I will move the Earth'. Poor Archimedes. Imagine if he could have seen today: a day when the leverage of all those bits and bytes can mean the audience for your media, or the downloads for your app know few limits. 
        </Headings.h4>
        </div>
        <div style={{ marginTop: "50px" }}>
        <Headings.h5>
        Archimedes had guts - and he backed his convictions. He knew the power of leverage.
        </Headings.h5>
        </div>
        <div style={{ marginTop: "50px" }}>
        <Headings.h5>
        But today is your day. Today is your time to take advantage of the leverage that flows from the bits, bytes and sound-bites of code and media. 
        </Headings.h5>
        </div>

        <div style={{ marginTop: "50px" }}>
        <Headings.h5>
        That Leverage is your daily encouragement to get out there and grab yourself some of that leverage. And we will do everything we can to get you out there: tips, tools and tales of the new world of leverage (and ask us to dance and we'll dance too!).
        </Headings.h5>
        </div>
        <div style={{ marginTop: "50px" }}>
        <Headings.h5>
        By signing up to That Leverage you’ve signalled your intent to move your world.  Let’s go give old Archimedes a run for his money.
        </Headings.h5>
        </div>
        <div style={{ marginTop: "75px" }}>
        <Headings.h5>
        That Leverage Team (p.s. now go and check your inbox for that article we sent!)
        </Headings.h5>
        </div>

      </Section>
    </Layout>
  );
}

export default Welcome;

export const pageQuery = graphql`
    query {
      imageOne: file(relativePath: { eq: "james-pond-26vBUtlufFo-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 10000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
`;
